import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-tailwind/react";

const Footer = () => {
  const { t } = useTranslation();

  const LINKS = [
    {
      text: t("lang.footer.URL1"),
      href: "https://www.egener.es/politica-de-privacidad/",
    },
    {
      text: t("lang.footer.URL2"),
      href: "https://www.egener.es/politica-de-privacidad/",
    },
    {
      text: t("lang.footer.URL3"),
      href: "https://www.egener.es/centro-de-seguridad-y-transparencia/",
    },
    { text: t("lang.footer.URL4"), href: "https://www.egener.es/ayuda/" },
    { text: t("lang.footer.URL5"), href: "https://haveibeenpwned.com/" },
    {
      text: t("lang.footer.URL6"),
      href: "https://www.egener.es/preguntas-frecuentes/",
    },
    { text: t("lang.footer.URL7"), href: "https://www.egener.es/ia/" },
    { text: t("lang.footer.URL8"), href: "https://www.egener.es/inversiones/" },
    { text: t("lang.footer.URL9"), href: "https://www.egener.es/work/" },
  ];

  return (
    <footer className="relative w-full bg-black py-4">
      <div className="mx-auto w-full max-w-7xl px-8 py-2">
        <Typography
          variant="h5"
          className="md:mb-6 md:mr-20 text-center md:text-right text-white"
        >
          {t("lang.footer.header")}
        </Typography>

        <div className="grid grid-cols-2 pt-6 sm:pt-0  sm:grid-cols-3 justify-between gap-4">
          {LINKS.map((items, index) => (
            <Typography
              key={index}
              as="a"
              target="_blank"
              href={items.href}
              color="gray"
              className="py-1.5 font-normal transition-colors text-white"
            >
              {items.text}
            </Typography>
          ))}
        </div>

        <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal md:mb-0 text-white"
          >
            {t("lang.footer.botton")}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
