import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Checkbox } from "@material-tailwind/react";

import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";

import { register } from "../../../actions/authAction";
import { SET_TEMP_USER } from "../../../constants/Permission";

const Policy = ({
  prevStep,
  state,
  nextStep,
  loading,
  register,
  isAuthenticated,
  user,
}) => {
  const { t } = useTranslation();
  const [policy, setPol] = useState(false);
  const [routing, setRouting] = useState(false);

  const handleCheck = (e) => {
    setPol(e.target.checked);
  };

  const Continue = (e) => {
    e.preventDefault();
    state.policy = policy;
    register(state)
      .then((data) => {
        if (data.clientSecret !== null) {
          nextStep();
        } else {
          setRouting(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  if (isAuthenticated && user.permissions !== undefined) {
    let newPermit = Object.keys(user.permissions);
    if (newPermit.includes(SET_TEMP_USER)) {
      return <Navigate to="/temporary" />;
    }
  }

  return (
    <div className="flex flex-col items-center space-y-2  justify-center min-h-screen">
      {loading ? (
        <Spinner />
      ) : (
        <div className="max-w-sm flex flex-col p-4 space-y-6">
          <Typography variant="h3">{t("lang.policy.textH")}</Typography>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
            />
          </svg>
          <Typography>{t("lang.policy.text1")}</Typography>
          <div className="flex">
            <Checkbox value={policy} onChange={handleCheck} />
            <p className="mt-2.5">{t("lang.policy.text2")}</p>
          </div>
          <div className="flex justify-between w-full pt-12">
            <button
              onClick={Previous}
              className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <button
              onClick={Continue}
              disabled={!policy}
              className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none disabled:opacity-30"
            >
              {t("lang.policy.text3")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Policy.propTypes = {
  register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  loading: state.loadReducer.loading,
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { register })(Policy);
