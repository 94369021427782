import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import loadReducer from "./loadReducer";
import tempReducer from "./tempReducer";
import adminReducer from "./adminReducer";
import historyReducer from "./historyReducer";
import paymentReducer from "./paymentReducer";

export default combineReducers({
  authReducer,
  alertReducer,
  loadReducer,
  tempReducer,
  adminReducer,
  historyReducer,
  paymentReducer,
});
