import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-tailwind/react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

const FooterTwo = ({ isAuthenticated }) => {
  const { t } = useTranslation();

  const LINKS = [
    {
      text: t("lang.footer.URL1"),
      href: "https://www.egener.es/politica-de-privacidad/",
    },
    {
      text: t("lang.footer.URL2"),
      href: "https://www.egener.es/politica-de-privacidad/",
    },
    {
      text: t("lang.footer.URL3"),
      href: "https://www.egener.es/centro-de-seguridad-y-transparencia/",
    },
    { text: t("lang.footer.URL4"), href: "https://www.egener.es/ayuda/" },
    { text: t("lang.footer.URL5"), href: "https://haveibeenpwned.com/" },
    {
      text: t("lang.footer.URL6"),
      href: "https://www.egener.es/preguntas-frecuentes/",
    },
    { text: t("lang.footer.URL7"), href: "https://www.egener.es/ia/" },
    { text: t("lang.footer.URL8"), href: "https://www.egener.es/inversiones/" },
    { text: t("lang.footer.URL9"), href: "https://www.egener.es/work/" },
  ];

  return (
    <>
      {isAuthenticated ? (
        <footer className="relative w-full bg-gray-100 py-4">
          <div className="mx-auto w-full max-w-7xl px-8 py-2">
            <Typography
              variant="h5"
              className="md:mb-6 md:mr-20 text-center md:text-right text-black"
            >
              {t("lang.footer.header")}
            </Typography>

            <div className="grid grid-cols-3 justify-between gap-4">
              {LINKS.map((items, index) => (
                <Typography
                  key={index}
                  as="a"
                  href={items.href}
                  target="_blank"
                  color="gray"
                  className="py-1.5 font-normal transition-colors hover:text-blue-gray-900 text-black"
                >
                  {items.text}
                </Typography>
              ))}
            </div>

            <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
              <Typography
                variant="small"
                className="mb-4 text-center font-normal md:mb-0 text-black"
              >
                {t("lang.footer.botton")}
              </Typography>
            </div>
          </div>
        </footer>
      ) : (
        ""
      )}
    </>
  );
};

FooterTwo.propTypes = {
  user_type: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
});

export default connect(mapStateToProps)(FooterTwo);
