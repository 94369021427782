import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import Backend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { reactI18nextModule } from "react-i18next";

import englishJson from "./locales/en/english.json";
import spanishJson from "./locales/es/spanish.json";
import responseEn from "./locales/en/response.json";
import responseEs from "./locales/es/response.json";

const resources = {
  en: {
    translation: {
      lang: englishJson,
      response: responseEn,
    },
  },
  es: {
    translation: {
      lang: spanishJson,
      response: responseEs,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     debug: true,
//     initImmediate: false,
//     fallbacklng: "en",
//     returnObjects: true,
//     lng: "en",
//   });

export default i18n;
