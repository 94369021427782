import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n"
import "./index.css";
 
import { ThemeProvider } from "@material-tailwind/react";
 
const root = ReactDOM.createRoot(document.getElementById("root"));
 
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading...">
    <ThemeProvider>
      <App />
    </ThemeProvider>
    </React.Suspense>
  </React.StrictMode>
);
