import React from "react";
import "./layout.css";
import { NavLink, Link } from "react-router-dom";
import { LANGUAGES } from "../../constants/Languages";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

//Styling....
import {
  Navbar,
  Drawer,
  Typography,
  ButtonGroup,
  Button,
  IconButton,
} from "@material-tailwind/react";

import logo from "../images/logo.png";

const Header = () => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const changeLang = (lng) => {
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpen(false)
    );
  }, []);

  return (
    <Navbar className="w-full border-none shadow-none bg-opacity-100 backdrop-blur-none backdrop-saturate-0 bg-black rounded-none px-4 py-2 lg:px-8 lg:py-4 max-w-full">
      <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
        <Link to="/" className="w-44 mr-4">
          <img src={logo} alt="defender mail" />
        </Link>
        <div className="flex items-center gap-x-4">
          {/* <Link to="/pricing">
            <p className="text-white text-lg">{t("lang.pricing.name")}</p>
          </Link> */}
          <ButtonGroup
            id="btnGroup"
            className="shadow-none hover:shadow-none flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
            {LANGUAGES.map((lng) => {
              return (
                <Button
                  key={lng.code}
                  onClick={() => changeLang(lng.code)}
                  className="bg-black shadow-none hover:shadow-none border-none text-white"
                >
                  {lng.label}
                </Button>
              );
            })}
          </ButtonGroup>
          <NavLink
            to="/login"
            variant="text"
            size="sm"
            className="hidden lg:inline-block border border-black text-white py-2 px-3 hover:border hover:border-white rounded-full"
          >
            <span>{t("lang.navbar.login")}</span>
          </NavLink>
          <NavLink
            variant="gradient"
            to="/register"
            size="sm"
            className="hidden lg:inline-block rounded-full bg-white py-2 px-3"
          >
            <span>{t("lang.navbar.Signup")}</span>
          </NavLink>
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          onClick={openDrawer}
        >
          {open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <Drawer
        open={open}
        onClose={closeDrawer}
        className="h-screen bg-transparent w-3/5"
      >
        <div className="bg-black py-4 space-y-2 mt-14">
          <IconButton
            className="float-right"
            variant="text"
            color="blue-gray"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>

          <div className="flex flex-col space-y-2 text-white px-4">
            <NavLink
              to="/login"
              fullwidth="true"
              variant="text"
              size="sm"
              className=""
            >
              <span className="text-xl">{t("lang.navbar.login")}</span>
            </NavLink>
            <NavLink
              to="/register"
              fullwidth="true"
              variant="gradient"
              size="sm"
              className=""
            >
              <span className="text-xl">{t("lang.navbar.Signup")}</span>
            </NavLink>
          </div>
        </div>
      </Drawer>
    </Navbar>
  );
};

export default connect()(Header);
