import React from "react";
import { setAlert } from "../../actions/alertAction";
import { useDispatch } from "react-redux";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // return_url: `${process.env.REACT_APP_DEV_URL}/profile`,
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_PROD_URL}/profile`,
      },
    });

    console.log("STRIPE RESULT - ", result);

    if (result.error) {
      dispatch(setAlert(result.error.decline_code, "red"));

      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-2">
      <PaymentElement />
      <button
        className="w-full bg-black text-white hover:border-black hover:bg-white hover:text-black border rounded-lg p-2 delay-75 transition-all"
        disabled={!stripe}
      >
        Submit
      </button>
    </form>
  );
};

export default Checkout;
