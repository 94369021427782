import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { pricingDetails } from "../../../actions/userAction";
import Plans from "../../Checkout/Plans";

const Pricing = ({ nextStep, state }) => {
  const [planContent, setPlanContent] = useState([]);
  const { t } = useTranslation();

  const Continue = ({ plan, priceId }) => {
    state.plan = plan;
    state.priceId = priceId;
    nextStep();
  };

  const getPlans = async () => {
    const plans = await pricingDetails();
    setPlanContent(plans);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="w-full flex justify-center bg-[#13072d]">
      <section>
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
              {t("lang.pricing.title")}
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-flow-col lg:auto-cols-auto  sm:gap-6 xl:gap-10 lg:space-y-0">
            <Plans totalPlans={planContent} continueBtn={Continue} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
