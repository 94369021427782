export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const USER_LOADED = "USER_LOADED";

export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOG_OUT = "LOG_OUT";

export const LOADING = "LOADING";

export const EMAIL_LOAD = "EMAIL_LOAD";

export const ADMIN_LOAD = "ADMIN_LOAD";

export const HISTORY_LOAD = "HISTORY_LOAD";
export const EMAIL_HISTORY_LOAD = "EMAIL_HISTORY_LOAD";

export const PAY_CRED_IDS = "PAY_CRED_IDS";
