import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

import {
  updateProfile,
  changePassword,
  deleteAccount,
  cancelSubscription,
} from "../../actions/userAction";
import {
  Typography,
  Button,
  Dialog,
  Input,
  IconButton,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import "react-datepicker/dist/react-datepicker.css";
import Divider from "@mui/material/Divider";
import Sidebar from "../layout/Sidebar";
import Profile_sidebar from "../layout/Profile_sidebar";
import Foot_but from "../layout/Foot_but";
import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";
import Cropper from "./Cropper";

import Permission from "../Permissions/Permission";
import { SET_FOOT_BUT, SET_CANCEL_SUBS } from "../../constants/Permission";

const Profile = ({
  user,
  updateProfile,
  changePassword,
  cancelSubscription,
  deleteAccount,
}) => {
  const [inputData, setInputData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    user_id: user.id,
    country_code: user.country_code,
    mobile: user.mobile,
    curPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { t } = useTranslation();

  const isIdle = useIdleDetection(600000); // 10 minutes

  const [value, setValue] = useState(user.mobile);

  let { first_name, last_name, mobile, country_code, email } = inputData;

  const [open, setOpen] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);

  const [openPwd, setOpenPwd] = React.useState(false);

  const [dis, setDis] = useState(true);

  const handleOpen = () => setOpen(!open);
  const handleOpenDel = () => setOpenDel(!openDel);

  const handleOpenPwd = () => setOpenPwd((cur) => !cur);

  const onChangeInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const cancelAccount = () => {
    cancelSubscription(user.id);
    deleteAccount({ userType: user.user_type, id: user.id });
    handleOpenDel();
  };

  const cancelPlan = () => {
    cancelSubscription(user.id);
    setOpen(!open);
  };

  if (isIdle) {
    logout();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    updateProfile({
      first_name,
      last_name,
      country_code,
      mobile,
      user_id: user.id,
      email: user.email,
    });
  };

  const splitMobileNumb = () => {
    let mobileNumb = formatPhoneNumberIntl(value).split(/^(\+\d{1,4})(\d+)$/);
    let cc = mobileNumb[0].split(" ");
    let mainNumb = cc.slice(1).join("");

    setInputData({ ...inputData, country_code: cc[0], mobile: mainNumb });
  };

  useEffect(() => {
    splitMobileNumb();
    if (first_name.length > 1 && isValidPhoneNumber(String(value))) {
      setDis(false);
    } else setDis(true);
  }, [first_name, value]);

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Profile_sidebar />
      {/* <Profile_sidebar /> */}
      <Divider orientation="vertical" flexItem />
      <div className="w-full flex flex-col space-y-4 md:space-y-0">
        <div className="flex flex-col gap-0 md:flex-row md:space-x-6 justify-evenly mt-6 px-4">
          <Cropper />

          <div className="flex-1 ">
            <Typography className="text-deep-purple-400 font-semibold border-b-2 border-b-deep-purple-400 text-center w-1/2 mb-4">
              {t("lang.sidebar2.text1")}
            </Typography>

            <form className="flex flex-col pt-6 space-y-6" onSubmit={onSubmit}>
              <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4">
                <Input
                  variant="static"
                  label="First Name"
                  size="lg"
                  name="first_name"
                  value={first_name}
                  onChange={onChangeInput}
                  required
                />
                <Input
                  variant="static"
                  label="Last Name"
                  size="lg"
                  name="last_name"
                  value={last_name}
                  onChange={onChangeInput}
                />
              </div>

              <Input
                variant="static"
                label="Email"
                size="lg"
                name="email"
                value={email}
                onChange={onChangeInput}
                required
              />

              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                className="py-2 px-4"
              />
              <Button
                type="submit"
                variant="outlined"
                className="border-none rounded !bg-black text-white ml-auto self-start disabled:opacity-30"
                disabled={dis}
              >
                SAVE
              </Button>
            </form>
          </div>
          <div className="flex items-center space-y-2 flex-col">
            <Button
              type="button"
              variant="outlined"
              onClick={handleOpenDel}
              className="rounded bg-white text-black hover:bg-black hover:text-white ml-auto self-start border border-black"
            >
              {t("lang.sidebar2.button6")}
            </Button>
            <Dialog
              open={openDel}
              handler={handleOpenDel}
              size="xs"
              className="bg-blue-gray-100"
            >
              <DialogHeader>
                <Typography variant="h4" color="blue-gray">
                  {t("lang.modal.delete_account.title")}
                </Typography>
                <IconButton
                  size="sm"
                  color="white"
                  variant="text"
                  className="!absolute right-3.5 top-3.5 bg-black hover:bg-black"
                  onClick={handleOpenDel}
                >
                  <XMarkIcon className="h-4 w-4 stroke-2" />
                </IconButton>
              </DialogHeader>
              <DialogBody className="space-y-7">
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>

                  <div>
                    <Typography variant="h6" className="text-black">
                      {t("lang.modal.delete_account.feature1.title")}
                    </Typography>
                    <p className="text-black">
                      {t("lang.modal.delete_account.feature1.description")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                    />
                  </svg>

                  <Typography variant="h6" className="text-black">
                    {t("lang.modal.delete_account.feature2.title")}
                  </Typography>
                </div>
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                    />
                  </svg>

                  <Typography variant="h6" className="text-black">
                    {t("lang.modal.delete_account.feature3.title")}
                  </Typography>
                </div>
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="filled"
                  color="red"
                  onClick={handleOpenDel}
                  className="mr-1"
                >
                  <span>{t("lang.modal.cancelBtn")}</span>
                </Button>
                <Button
                  variant="filled"
                  onClick={cancelAccount}
                  className="bg-black text-white shadow-none hover:shadow-none"
                >
                  <span>{t("lang.modal.continueBtn")}</span>
                </Button>
              </DialogFooter>
            </Dialog>

            <Permission permit={SET_CANCEL_SUBS}>
              <Button
                type="button"
                variant="outlined"
                onClick={handleOpen}
                className="rounded bg-white text-black hover:bg-black hover:text-white ml-auto self-start border border-black"
              >
                {t("lang.sidebar2.button5")}
              </Button>
              <Dialog
                open={open}
                handler={handleOpen}
                size="xs"
                className="bg-blue-gray-100"
              >
                <DialogHeader>
                  <Typography variant="h4" color="blue-gray">
                    {t("lang.modal.cancel_Subscription.title")}
                  </Typography>
                  <IconButton
                    size="sm"
                    color="white"
                    variant="text"
                    className="!absolute right-3.5 top-3.5 bg-black hover:bg-black"
                    onClick={handleOpen}
                  >
                    <XMarkIcon className="h-4 w-4 stroke-2" />
                  </IconButton>
                </DialogHeader>
                <DialogBody className="space-y-7">
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>

                    <Typography variant="h6" className="text-black">
                      {t("lang.modal.cancel_Subscription.feature1.title")}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                      />
                    </svg>

                    <Typography variant="h6" className="text-black">
                      {t("lang.modal.cancel_Subscription.feature2.title")}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                      />
                    </svg>

                    <Typography variant="h6" className="text-black">
                      {t("lang.modal.cancel_Subscription.feature3.title")}
                    </Typography>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="filled"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                  >
                    <span>{t("lang.modal.cancelBtn")}</span>
                  </Button>
                  <Button
                    variant="filled"
                    onClick={cancelPlan}
                    className="bg-black text-white shadow-none hover:shadow-none"
                  >
                    <span>{t("lang.modal.continueBtn")}</span>
                  </Button>
                </DialogFooter>
              </Dialog>
            </Permission>
          </div>
        </div>
        <Permission permit={SET_FOOT_BUT}>
          <Foot_but user={user} />
        </Permission>
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, {
  updateProfile,
  cancelSubscription,
  deleteAccount,
  changePassword,
  logout,
})(Profile);
