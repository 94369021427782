import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lastLoginDetails } from "../../actions/adminAction";
import Spinner from "../layout/Spinner";
import Sidebar from "../layout/Sidebar";
import Divider from "@mui/material/Divider";
import { Typography } from "@material-tailwind/react";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const LastLogin = ({ loading, lastLoginDetails, logout }) => {
  const [loadData, setLoadData] = useState({});
  const isIdle = useIdleDetection(600000); // 10 minutes

  if (isIdle) {
    logout();
  }

  useEffect(() => {
    lastLoginDetails()
      .then((res) => setLoadData(res))
      .catch((err) => setLoadData(err));
  }, []);
  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      {loading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col my-4 px-4">
          <Typography className="text-lg">
            First Name: {loadData.first_name}
          </Typography>
          <Typography className="text-lg">
            Last Name: {loadData.last_name}
          </Typography>
          <Typography className="text-lg">
            Login time : {loadData.login_time}
          </Typography>
        </div>
      )}
    </div>
  );
};

LastLogin.propTypes = {
  user: PropTypes.object,
  initEmailList: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  loadEmailHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  lastLoginDetails, logout
})(LastLogin);
