import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Input } from "@material-tailwind/react";

const Name = ({ prevStep, nextStep, state }) => {
  const { t } = useTranslation();

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dis, setDis] = useState(true);

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  useEffect(() => {
    if (first === "" || last === "") {
      setDis(true);
    } else {
      setDis(false);
      state.first_name = first;
      state.last_name = last;
    }
  }, [first, last]);

  return (
    <div className="flex flex-col items-center  justify-center min-h-screen">
      <div className="max-w-sm flex flex-col p-4 space-y-6">
        <Typography variant="h3">{t("lang.about.text1")}</Typography>
        <Typography variant="paragraph">{t("lang.about.text2")}</Typography>
        <div className="space-y-11">
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder={t("lang.about.firstnamePlaceholder")}
              className="h-full w-full border-b border-blue-gray-200 bg-gray-200 pt-2 rounded-lg px-2 mt-2 pb-1.5 font-sans text-sm font-normal focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              value={first}
              onChange={(e) => setFirst(e.target.value)}
            />
            <label
              className="after:content[' '] pointer-events-none absolute left-0  -top-4 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight transition-all after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-black peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-black
            "
            >
              {t("lang.about.firstname")}
            </label>
          </div>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder={t("lang.about.lastnamePlaceholder")}
              className="h-full w-full border-b border-blue-gray-200 bg-gray-200 pt-2 rounded-lg px-2 mt-2 pb-1.5 font-sans text-sm font-normal focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              value={last}
              onChange={(e) => setLast(e.target.value)}
            />
            <label className="after:content[' '] pointer-events-none absolute left-0  -top-4 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight transition-all after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-black peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-black">
              {t("lang.about.lastname")}
            </label>
          </div>
        </div>
        <div className="flex justify-between w-full pt-12">
          <button
            onClick={Previous}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            disabled={dis}
            onClick={Continue}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none disabled:opacity-30"
          >
            {t("lang.about.button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Name;
