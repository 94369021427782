import api from "../utils/api";
import { setAlert } from "./alertAction";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOG_OUT,
  LOADING,
  ADMIN_LOAD,
  PAY_CRED_IDS,
} from "./types";

import i18n from "../i18n";
import { Navigate } from "react-router-dom";

// Load User
export const loadUser = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    // Set token in the Authorization header
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: LOADING, payload: true });

    const res = await api.get(`/user?user_id=${id}`, config);
    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    if (error) {
      const token = sessionStorage.getItem("token");

      dispatch({ type: LOADING, payload: false });

      if (token !== null) {
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const otpSend = (email) => async (dispatch) => {
  try {
    const { language } = i18n;
    const res = await api.post("/registerStep1", { email, language });
    return res;
  } catch (error) {
    dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
    if (error.response.data.valid_domain) {
      dispatch(
        setAlert(
          i18n.t(`response.${error.response.data.valid_domain}`),
          "yellow"
        )
      );
    }
  }
};

export const otpVerify = (_email, otp, type) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/otpVerify", { email: _email, otp, type });

    if (type === "register") {
      dispatch({ type: LOADING, payload: false });
      return true;
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data.user,
      });
      const { token, user } = res.data.data;
      const { id, last_login_id } = user;

      sessionStorage.setItem("token", token);
      sessionStorage.setItem("login_id", last_login_id);
      sessionStorage.setItem("user", id);

      dispatch(loadUser(id));
      dispatch({ type: LOADING, payload: false });
      return user;
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
  }
};

export const register = (state) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/registerStep2", { ...state });

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data.data.user,
    });
    const { token, user, res: paymemtCred } = res.data.data;
    const { id, last_login_id } = user;

    sessionStorage.setItem("token", token);
    sessionStorage.setItem("login_id", last_login_id);
    sessionStorage.setItem("user", id);

    dispatch(loadUser(id));

    dispatch({
      type: PAY_CRED_IDS,
      payload: { ...paymemtCred },
    });

    dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));

    dispatch({ type: LOADING, payload: false });
    return paymemtCred;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const last_login_id = sessionStorage.getItem("login_id");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: LOADING, payload: true });
    const res = await api.get(`/logout?last_login_id=${last_login_id}`, config);
    dispatch({ type: LOG_OUT });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
  }
};

export const loginUser = (email) => async (dispatch) => {
  try {
    const { language } = i18n;
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/login", { email, language });
    dispatch({ type: LOADING, payload: false });
    return res;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"));
  }
};

export const resetPassword = (email, token, password) => async (dispatch) => {
  try {
    const res = await api.post("/resetPassword", {
      email,
      token,
      password,
    });
    if (res.data == "success") {
      dispatch(
        setAlert(
          "Your password is updated successfully! Please login!",
          "green"
        )
      );
      setTimeout(() => {
        return <Navigate to="/" />;
      }, 3000);
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "red")));
    }
  }
};
