import React, { useEffect } from "react";
import Checkout from "../../Checkout/Checkout";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";

const stripePromise = loadStripe(
  "pk_test_51PWjThRrjghUGeZjr9PlZD1tgMo669oei8s8nCijMBbY79hLr84QGr4xbS7sS7ObGqVxD4oyIsLbklhmAIO89A2y00qJZmB5bS"
);
// const stripePromise = loadStripe(process.env.REACT_APP_LOAD_STRIPE);

const Payment = ({ isAuthenticated, loading, payCredentials }) => {
  const { clientSecret } = payCredentials;
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <div className="flex flex-col items-center space-y-2  justify-center min-h-screen">
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col items-center justify-center my-4">
          <Elements stripe={stripePromise} options={options}>
            <Checkout />
          </Elements>
        </div>
      )}
    </div>
  );
};

Payment.propTypes = {
  isAuthenticated: PropTypes.bool,
  payCredentials: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
  loading: state.loadReducer.loading,
  payCredentials: state.paymentReducer.payCredentials,
});

export default connect(mapStateToProps)(Payment);
