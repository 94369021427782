import {
  HISTORY_LOAD
} from '../actions/types';

const initialState = {
  history: []
};

function historyReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HISTORY_LOAD:
      return {
        ...state,
        history: payload
      };
    default:
      return state;
  }
}

export default historyReducer;
