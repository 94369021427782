import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Input,
  DialogFooter,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Select, Option } from "@material-tailwind/react";
import { loadContactUs } from "../../actions/historyAction";
import Sidebar from "../layout/Sidebar";
import Divider from "@mui/material/Divider";
import Spinner from "../layout/Spinner";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const Incidents = ({ loading, user, history, loadContactUs, logout }) => {
  const { t } = useTranslation();
  const isIdle = useIdleDetection(600000); // 10 minutes

  const TABLE_HEAD = [
    t("lang.tableHead4.1"),
    t("lang.tableHead4.2"),
    t("lang.tableHead4.3"),
    t("lang.tableHead4.4"),
    t("lang.tableHead4.6"),
    t("lang.tableHead4.7"),
    
  ];
  const [pageSize, setPageSize] = useState("5");
  // const handlePageSize = (value) => {
  //   setPageSize(value);
  // };
  const [search, setSearch] = useState("");
  const [searchName, setSearchName] = React.useState("");
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(4);
  const [pageIndex, setPageIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState(null);

  if (isIdle) {
    logout();
  }

  const handleOpen = (subject, message, value) => {
    setOpen(!open);
    setSize(value);
    setsubject(subject);
    setmessage(message);
  };
  const history_listData = history.length !== 0 ? history.data : [];
  let getData = [];
  let searchData = [];
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePrevious = () => {
    const newIndex = Math.max(0, firstIndex - parseInt(pageSize));
    setFirstIndex(newIndex);
    setLastIndex(Math.min(history_listData.length - 1, newIndex + parseInt(pageSize) - 1));
    setPageIndex(newIndex);
  };
  
  const handleNext = () => {
    if (lastIndex < history_listData.length - 1) {
      const newIndex = Math.min(
        history_listData.length - 1,
        lastIndex + 1
      );
      setFirstIndex(newIndex);
      setLastIndex(Math.min(history_listData.length - 1, newIndex + parseInt(pageSize) - 1));
      setPageIndex(newIndex);
    }
  };
  
  const handlePageSize = (value) => {
    setPageSize(value);
  };
  
  // useEffect(() => {
  //   loadContactUs({ id: user.id, type: user.user_type });
  //   setLastIndex(pageSize - 1);
  // }, []);
  useEffect(() => {
    loadContactUs({ id: user.id, type: user.user_type });
    setLastIndex(pageSize - 1);
  }, [pageSize]);

  let countData = 0;
  for (let i = 0; i < history_listData.length; i++) {
    if (
      (history_listData[i] &&
        history_listData[i].email.toLowerCase().includes(search.toLowerCase())) ||
      history_listData[i].name.toLowerCase().includes(search.toLowerCase())
    ) {
      searchData.push(history_listData[i]);
      countData += 1;
    }
  }

  const get_table_data = (firstIndex, lastIndex) => {
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (searchData[i]) {
        getData.push(searchData[i]);
      }
    }
  };
  get_table_data(firstIndex, lastIndex);
  const TABLE_ROWS = getData.map(
    ({ user_id, email, name, created_at, subject, message }, index) => {
      return {
        id: user_id,
        no: pageIndex + index + 1,
        email,
        name,
        created_at,
        subject,
        message,
      };
    }
  );

  const formatCreatedAt = (created_at) => {
    const date = new Date(created_at);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`; // Adjust locale and options as needed
  };
  const [dsubject, setsubject] = useState();
  const [dmessage, setmessage] = useState();

  return (
    <>
      <Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        className="overflow-scroll"
      >
        <DialogHeader style={{ fontSize: "20px", top: "0" }}>
          {/* {t("lang.tableHead4.6")}: */}
          {dsubject}
        </DialogHeader>
        <DialogBody style={{ color: "black" }}>{dmessage}</DialogBody>
        <DialogFooter className="mt-auto">
          <Button className="!bg-black" onClick={() => handleOpen(null)}>
            <span>{t("lang.incident.obj5")}</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <div className="flex flex-col lg:flex-row pb-6 w-full">
        <Sidebar />
        <Divider orientation="vertical" flexItem />
        <div className="w-full flex flex-col">
          <Card className="shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none m-0"
            >
              <div className="flex flex-col justify-between md:flex-row md:items-center p-4 pb-0">
                <Typography variant="h5" color="blue-gray">
                  {t("lang.incident.obj1")}
                </Typography>
                <div>
                  {t("lang.incident.obj2")}
                  <b>{countData}</b>
                </div>
                <div className="w-full md:w-72">
                  <Input
                    id="search"
                    onChange={handleSearch}
                    label={<span className="mr-8">{t("lang.incident.obj3")}</span>}
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <div className="w-full flex justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <CardBody className="px-0">
                  <div className="overflow-auto h-[60dvh]">
                    <table className="w-full pb-6 text-center">
                      <thead>
                        <tr className="border-y border-black-gray-100 bg-black-gray-50">
                          {TABLE_HEAD.map((head) => (
                            <th key={head} className="p-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {TABLE_ROWS.map(
                          (
                            {
                              no,
                              id,
                              email,
                              name,
                              created_at,
                              subject,
                              message,
                            },
                            index
                          ) => {
                            const isLast = index === TABLE_ROWS.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-black-gray-50";

                            return (
                              <tr
                                key={no}
                                className="font-bold cursor-pointer p-4"
                                onClick={() => {
                                  handleOpen(subject, message, "xl");
                                }}
                              >
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography
                                      variant="small"
                                      color="black-gray"
                                      className="font-bold cursor-pointer"
                                    >
                                      {no}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="black-gray"
                                    className="font-normal cursor-pointer"
                                  >
                                    {name}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="black-gray"
                                    className="font-normal cursor-pointer"
                                  >
                                    {email}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="black-gray"
                                    className="font-normal cursor-pointer"
                                  >
                                    {formatCreatedAt(created_at)}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="black-gray"
                                    className="font-normal cursor-pointer"
                                  >
                                    {subject}
                                  </Typography>
                                </td>
                                <td
                                  className={classes}
                                  style={{
                                    overflow: "hidden",
                                    maxWidth: "180px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <Typography
                                    variant="small"
                                    color="black-gray"
                                    className="font-normal cursor-pointer"
                                  >
                                    {message}
                                  </Typography>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <CardFooter className="flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-between border-t border-blue-gray-50 p-4">
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={handlePrevious}
                    className="w-full md:w-auto"
                  >
                    {t("lang.pageFooter.obj1")}
                  </Button>
                  <div className="w-full md:w-auto">
                    <Select
                      label={t("lang.pageFooter.obj3")}
                      value={pageSize}
                      onChange={handlePageSize}
                    >
                      <Option value={"5"}>5</Option>
                      <Option value={"10"}>10</Option>
                      <Option value={"20"}>20</Option>
                      <Option value={"50"}>50</Option>
                      <Option value={"100"}>100</Option>
                    </Select>
                  </div>

                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={handleNext}
                    className="w-full md:w-auto"
                  >
                    {t("lang.pageFooter.obj2")}
                  </Button>
                </CardFooter>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

Incidents.propTypes = {
  user: PropTypes.object,
  loadContactUs: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  history: state.historyReducer.history,
  emailList: state.tempReducer.temporary_email,
  userList: state.adminReducer.users,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  loadContactUs,
  logout,
})(Incidents);
