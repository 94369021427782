import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Alert } from "@material-tailwind/react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>
  );
}

const AlertComponent = ({alerts}) => {
  return (
    <div className="container w-[50%] md:w-[30%] opacity-90 fixed top-0 right-0 z-40">
      {
        alerts.map((alert, i) => (
          <Alert key={i} icon={<Icon />} color={alert.alertType} className="m-1">{alert.msg}</Alert>
        ))
      }
    </div>
  )
}

AlertComponent.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  alerts: state.alertReducer
});

export default connect(mapStateToProps)(AlertComponent);