import React, { useEffect, useState } from "react";
import { logout } from "../../actions/authAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  faClock,
  faEnvelope,
  faInfoCircle,
  faMicrochip,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";

import { PowerIcon } from "@heroicons/react/24/solid";

import manualicon from "../images/manualicon.png";
import automode from "../images/automode.png";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Permission from "../Permissions/Permission";
import {
  SET_AI_MODE,
  SET_AUTO_MODE,
  SET_MANUAL_MODE,
  SET_USER_MNG,
  SET_TEMP_ADMIN,
  SET_INCIDENTS,
  SET_LAST_LOGINS,
  SET_ROLES,
} from "../../constants/Permission";

const Sidebar = ({
  user,
  isAuthenticated,
  user_type,
  logout,
  createTempAuto,
  handleOpenManual,
  generatesWithAiTemp,
}) => {
  const { t } = useTranslation();

  const logOut = () => {
    logout();
    // logout({ last_login_id: user.last_login_id });
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check your condition here
    setIsLoading(!(user_type && isAuthenticated === true));
  }, [user_type, isAuthenticated]);

  if (isLoading) {
    // Render loading state or nothing
    return null; // or <Spinner /> or any other loading indicator
  }

  // console.log(user_type);

  return (
    <Card className="p-4 mt-2 border-none outline-none shadow-none">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          {t("lang.sidebar.text1")}
        </Typography>
      </div>
      <List>
        <Permission permit={SET_AUTO_MODE}>
          <ListItem
            className="mb-2 bg-gray-200 hover:bg-gray-400"
            onClick={createTempAuto}
          >
            <ListItemPrefix>
              <img src={automode} alt="automode" className="h-5 w-5" />
            </ListItemPrefix>
            <div>{t("lang.sidebar.button1")}</div>
          </ListItem>
        </Permission>

        <Permission permit={SET_MANUAL_MODE}>
          <ListItem
            className="mb-2 bg-gray-200 hover:bg-gray-400"
            onClick={handleOpenManual}
          >
            <ListItemPrefix>
              <img src={manualicon} alt="manual" className="h-5 w-5" />
            </ListItemPrefix>
            <div>{t("lang.sidebar.button2")}</div>
          </ListItem>
        </Permission>

        <Permission permit={SET_AI_MODE}>
          <ListItem
            onClick={generatesWithAiTemp}
            className="mb-2 bg-gray-200 hover:bg-gray-400"
          >
            <ListItemPrefix>
              <FontAwesomeIcon icon={faMicrochip} className="h-4 w-4" />
            </ListItemPrefix>
            <div>{t("lang.sidebar.button3")}</div>
          </ListItem>
        </Permission>

        {/* admin roles */}
        <Permission permit={SET_USER_MNG}>
          <Link to="/user_mng" className="mb-2">
            <ListItem className=" bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                <FontAwesomeIcon icon={faUsers} className="h-4 w-4" />
              </ListItemPrefix>
              <div>{t("lang.adminSidebar.button1")}</div>
            </ListItem>
          </Link>
        </Permission>

        <Permission permit={SET_TEMP_ADMIN}>
          <Link to="/temporary_admin" className="mb-2">
            <ListItem className=" bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                <FontAwesomeIcon icon={faInfoCircle} className="h-4 w-4" />
                {/* <LockClosedIcon className="h-5 w-5" /> */}
              </ListItemPrefix>
              <div>{t("lang.adminSidebar.button2")}</div>
            </ListItem>
          </Link>
        </Permission>

        <Permission permit={SET_INCIDENTS}>
          <Link to="/incidents" className="mb-2">
            <ListItem className=" bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4" />
                {/* <ComputerDesktopIcon className="h-5 w-5" /> */}
              </ListItemPrefix>
              <div>{t("lang.adminSidebar.button4")}</div>
            </ListItem>
          </Link>
        </Permission>

        <Permission permit={SET_LAST_LOGINS}>
          <Link to="/lastLogin" className="mb-2">
            <ListItem className=" bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                <FontAwesomeIcon icon={faClock} className="h-4 w-4" />
                {/* <CodeBracketSquareIcon className="h-5 w-5" /> */}
              </ListItemPrefix>
              <div>{t("lang.adminSidebar.button5")}</div>
            </ListItem>
          </Link>
        </Permission>

        <Permission permit={SET_ROLES}>
          <Link to="/roles" className="mb-2">
            <ListItem className=" bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                <FontAwesomeIcon icon={faGear} className="h-4 w-4" />
              </ListItemPrefix>
              <div>{t("lang.adminSidebar.button7")}</div>
            </ListItem>
          </Link>
        </Permission>
      </List>
    </Card>
  );
};

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
});

export default connect(mapStateToProps, { logout })(Sidebar);
