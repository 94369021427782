import {
  EMAIL_LOAD,
  EMAIL_HISTORY_LOAD
} from '../actions/types';

const initialState = {
  temporary_email: [],
  email_history:[]
};

function tempReducer(state = initialState, action) {
  const { type, payload } = action;
 

  switch (type) {
    case EMAIL_LOAD:
      return {
        ...state,
        temporary_email: payload
      };
      case EMAIL_HISTORY_LOAD:
      return {
        ...state,
        email_history: payload
      };
    default:
      return state;
  }
}


export default tempReducer;
