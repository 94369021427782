// import * as React from 'react'
import { useParams } from 'react-router-dom'

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { setAlert } from '../../actions/alertAction';
import { resetPassword } from '../../actions/authAction';

const ResetPassword = ({ setAlert, resetPassword }) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: ''
  });
  const { password, password2 } = formData;
  const { email, token } = useParams();
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'red');
    }
    else {
      resetPassword(email, token, password);
    }
  }
  return (
    <div className="container flex justify-center my-12 py-12">
      <Card color="transparent" shadow={false}>
        <Typography variant="h4" color="blue-gray">
          Reset your password
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={onSubmit}>
          <div className="mb-4 flex flex-col gap-6">
            <Input 
              type="password" 
              size="lg" 
              label="Password" 
              name="password"
              value={password}
              onChange={onChange}
            />
            <Input 
              type="password" 
              size="lg" 
              label="Confirm Password" 
              name="password2"
              value={password2}
              onChange={onChange}
            />
          </div>
          <Button type="submit" className="mt-6" fullWidth>
            Reset
          </Button>
        </form>
      </Card>
    </div>
  )
}

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired
};

export default connect(null, { setAlert, resetPassword })(ResetPassword);