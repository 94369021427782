// store our JWT in LS and set axios headers if we do have a token

const setUserId = (id) => {
  if (id) {
    sessionStorage.setItem('user', id);
  } else {
    sessionStorage.removeItem('user');
  }
};

export default setUserId;
