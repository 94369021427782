import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HeaderTwo from "../layout/HeaderTwo";
import FooterTwo from "../layout/FooterTwo";

const AuthRoute = ({
  component: Component,
  auth: { isAuthenticated },
  loading,
}) => {
  const location = useLocation();
  const { pathname } = location;
  // if (loading) return <Spinner />;
  if (isAuthenticated === true) {
    return (
      <>
        <HeaderTwo />
        <Component />
        <FooterTwo />
      </>
    );
  } else if (isAuthenticated != null && isAuthenticated === false) {
    if (pathname === "/login") {
      // return <LoadRoute component={Login} />;
      return <Navigate to="/login" />;
    } else if (pathname === "/register") {
      // return <LoadRoute component={Signup} />;
      return <Navigate to="/register" />;
    } else {
      return <Navigate to="/" />;
    }
  }
};

AuthRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps)(AuthRoute);
