import React from "react";
import Spinner from "../layout/Spinner";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-hero bg-cover 2xl:bg-[length:100vw_100vh] bg-no-repeat min-h-screen flex items-center justify-center lg:justify-start">
      <div className="flex flex-col space-y-4 p-4 lg:ml-14 lg:max-w-3xl">
        <h1 className="text-white text-5xl xl:text-7xl text-center">
          {t("lang.Home.homeText")}
        </h1>
        <NavLink
          to="/register"
          className="text-center text-2xl 2xl:text-3xl font-bold rounded-full bg-white py-4 px-12 self-center"
        >
          {t("lang.Home.registerBtn")}
        </NavLink>
      </div>
    </div>
  );
};

export default connect()(Home);
