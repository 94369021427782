import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Permission = ({ children, permit, user }) => {
  let newPermit = Object.keys(user.permissions);

  return <>{newPermit.includes(permit) ? children : ""}</>;
};

Permission.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Permission);
