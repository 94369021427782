import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Profile_sidebar from "../layout/Profile_sidebar";
import Divider from "@mui/material/Divider";
import Spinner from "../layout/Spinner";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { pricingDetails, createSubsctiption } from "../../actions/userAction";
import Plans from "../Checkout/Plans";
import Checkout from "../Checkout/Checkout";

const stripePromise = loadStripe(
  "pk_test_51PWjThRrjghUGeZjr9PlZD1tgMo669oei8s8nCijMBbY79hLr84QGr4xbS7sS7ObGqVxD4oyIsLbklhmAIO89A2y00qJZmB5bS"
);
// const stripePromise = loadStripe(process.env.REACT_APP_LOAD_STRIPE);

const UpgradeBtn = ({ user, loading, payCredentials, createSubsctiption }) => {
  const [planContent, setPlanContent] = useState([]);
  const { clientSecret } = payCredentials;
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const getPlans = async () => {
    const plans = await pricingDetails();
    plans.shift();
    setPlanContent(plans);
  };

  const upgradePlan = async ({ plan, priceId }) => {
    let forUpgrade = {
      user_id: user.id,
      customer_id: user.customer_id,
      priceId: priceId,
    };
    createSubsctiption(forUpgrade)
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Profile_sidebar />

      <Divider orientation="vertical" flexItem />

      <div className="w-full flex justify-center items-center">
        {loading ? (
          <Spinner />
        ) : clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <Checkout />
          </Elements>
        ) : (
          <div className="relative bottom-12">
            <Plans
              totalPlans={planContent}
              continueBtn={upgradePlan}
              showProtect={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

UpgradeBtn.propTypes = {
  user: PropTypes.object,
  payCredentials: PropTypes.object.isRequired,
  createSubsctiption: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  loading: state.loadReducer.loading,
  payCredentials: state.paymentReducer.payCredentials,
});

export default connect(mapStateToProps, { createSubsctiption })(UpgradeBtn);
