import React from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const LoadRoute = ({ component: Component, loading }) => {
  // if (loading) return <Spinner />;
  return (
    <>
      <Header />
      <Component />
      <Footer />
    </>
  );
};

LoadRoute.propTypes = {
  // auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps)(LoadRoute);
