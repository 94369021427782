import api from "../utils/api";
import { LOADING, HISTORY_LOAD } from "./types";
import { setAlert } from "./alertAction";
import i18n from "../i18n";


export const loadHistories = () => async (dispatch) => {
  try {
    const res = await api.get("/history/loadHistories");
    dispatch({
      type: HISTORY_LOAD,
      payload: res.data,
    });
  } catch (error) {
    if (error) {
      dispatch({ type: LOADING, payload: false });
      dispatch(
        setAlert(
          i18n.t(`response.${error.response.data.key}`),
          "red"
        )
      )
    }
  }
};

export const loadContactUs =
  ({ id, type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/loadContactUs?user_id=${id}&user_type=${type}`,
        config
      );
      dispatch({
        type: HISTORY_LOAD,
        payload: res.data,
      });
      dispatch({ type: LOADING, payload: false });
      // dispatch(setAlert(
      //   i18n.t(`response.${res.data.key}`),
      //   "green"
      // ))
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        setAlert(
          i18n.t(`response.${error.response.data.key}`),
          "red"
        )
        }
    }
  };

export const initHistoryList = () => async (dispatch) => {
  try {
    dispatch(loadHistories());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) => 
      setAlert(
        i18n.t(`response.${error.response.data.key}`),
        "red"
      ))
    }
  }
};
