//Common must permissions for users
export const SET_FOOT_BUT = "SET_FOOT_BUT"
export const SET_TEMP_USER = "SET_TEMP_USER"

//Free users
export const SET_AUTO_MODE = "SET_AUTO_MODE"
export const SET_AI_MODE = "SET_AI_MODE"
export const SET_UPGRADE_BTN = "SET_UPGRADE_BTN"

// Paid users
export const SET_MANUAL_MODE = "SET_MANUAL_MODE"
export const SET_DELETE_EMAILS = "SET_DELETE_EMAILS"
export const SET_TRASH_BIN = "SET_TRASH_BIN"
export const SET_CANCEL_SUBS = "SET_CANCEL_SUBS"

//Admin
export const SET_USER_MNG = "SET_USER_MNG"
export const SET_TEMP_ADMIN = "SET_TEMP_ADMIN"
export const SET_INCIDENTS = "SET_INCIDENTS"
export const SET_LAST_LOGINS = "SET_LAST_LOGINS" 
export const SET_ROLES = "SET_ROLES"