import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

export const useIdleDetection = (timeout) => {
  const [isIdle, setIsIdle] = useState(false);
  useIdleTimer({
    timeout,
    onIdle: () => setIsIdle(true),
    onActive: () => setIsIdle(false),
  });

  return isIdle;
};