import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Spinner from "../layout/Spinner";
import { Divider } from "@mui/material";
import { Select, Option } from "@material-tailwind/react";

import {
  Checkbox,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Button,
  Typography,
} from "@material-tailwind/react";

import { useIdleDetection } from "../Inactive/useIdleDetection";
import { logout } from "../../actions/authAction";
import {
  getRoles,
  getPermissions,
  assignRolesWithPermission,
  getRolesWithPermission,
} from "../../actions/adminAction";

import { useTranslation } from "react-i18next";

const Roles = ({
  loading,
  logout,
  getRoles,
  getPermissions,
  assignRolesWithPermission,
}) => {
  const [loadRoles, setRoles] = useState([]);
  const [loadPermissions, setLoadPermissions] = useState([]);
  const [alreadyChecked, setAlreadyChecked] = useState(null);

  const [assignRole, setSetAssignRole] = useState(null);
  const [permissions, setpermissions] = useState([]);

  const { t } = useTranslation();
  const isIdle = useIdleDetection(600000); // 10 minutes

  if (isIdle) {
    logout();
  }

  const handleCheckboxChange = (event) => {
    const checkedId = event.target.value;
    if (event.target.checked) {
      setpermissions([...permissions, checkedId]);
    } else {
      setpermissions(permissions.filter((id) => id !== checkedId));
    }
  };

  const handleRoles = async () => {
    assignRolesWithPermission({ assignRole, permissions })
      .then((res) => {
        setSetAssignRole(null);
        setpermissions([]);
      })
      .catch((err) => console.log(err));
  };

  const handlePermissions = (e) => {
    setSetAssignRole(e);

    const token = sessionStorage.getItem("token");

    fetch(`https://dev.api.demail.es/api/roles/${e}/permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const { data } = json;
        const { rolePermissions } = data;
        setAlreadyChecked(Object.values(rolePermissions));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (alreadyChecked !== null) {
      const result = alreadyChecked
        .map((id) => loadPermissions.find((obj) => obj.id === id))
        .filter((obj) => obj) // Filter out undefined results for non-matches
        .map((obj) => obj.name);

      setpermissions(result);
    }
  }, [alreadyChecked]);

  useEffect(() => {
    getRoles()
      .then((res) => setRoles(res))
      .catch((err) => setRoles(err));
  }, []);

  useEffect(() => {
    getPermissions()
      .then((res) => setLoadPermissions(res))
      .catch((err) => setLoadPermissions(err));
  }, []);

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      {loading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col mt-8 px-4 w-full">
          <div className="grid grid-cols-2">
            <Typography>Roles</Typography>

            <Select onChange={handlePermissions}>
              {loadRoles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {t(`lang.adminRoles.${role.name}`)}
                </Option>
              ))}
            </Select>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="flex flex-col">
            <Typography>Permissions</Typography>

            {assignRole == null ? (
              <Typography className="text-center font-semibold text-lg text-gray-500">
                {t(`lang.adminRoles.assignRole`)}
              </Typography>
            ) : (
              <>
                <List className="grid grid-cols-2 gap-x-4">
                  {loadPermissions.map((permit) => (
                    <ListItem className="p-0" key={permit.id}>
                      <label
                        htmlFor={permit.id}
                        className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Checkbox
                            id={permit.id}
                            ripple={true}
                            className="hover:before:opacity-0 checked:bg-black"
                            containerProps={{
                              className: "p-0",
                            }}
                            value={permit.name}
                            checked={permissions.includes(permit.name)}
                            onChange={(event) => {
                              handleCheckboxChange(event);
                            }}
                          />
                        </ListItemPrefix>
                        <Typography color="blue-gray" className="font-medium">
                          {t(`lang.adminRoles.${permit.name}`)}
                        </Typography>
                      </label>
                    </ListItem>
                  ))}
                </List>
                <Button
                  onClick={handleRoles}
                  className="mt-6 bg-black shadow-none  hover:shadow-none w-1/2 self-center"
                >
                  {t("lang.adminRoles.SET_P_2_R")}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Roles.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  getRoles,
  getPermissions,
  assignRolesWithPermission,
  logout,
})(Roles);
