import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

const Mobile = ({ prevStep, nextStep, state }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(state.mobile);
  const [dis, setDis] = useState(true);

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  useEffect(() => {
    let mobileNumb = formatPhoneNumberIntl(value).split(/^(\+\d{1,4})(\d+)$/);
    let cc = mobileNumb[0].split(" ");
    let mainNumb = cc.slice(1).join("");

    state.country_code = cc[0];
    state.mobile = mainNumb;
    
    if (isValidPhoneNumber(String(value)) || !value) {
      setDis(false);
    } else {
      setDis(true);
    }
  }, [value]);

  return (
    <div className="flex flex-col items-center space-y-2 justify-center min-h-screen">
      <div className="max-w-sm flex flex-col p-4 space-y-6">
        <Typography variant="h3">{t("lang.mobile.text1")}</Typography>
        <Typography variant="paragraph">{t("lang.mobile.text2")}</Typography>
        <Typography variant="h6">{t("lang.mobile.text3")}</Typography>
        <PhoneInput
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
          className="py-2 px-4"
        />
        <div className="flex justify-between w-full pt-12">
          <button
            onClick={Previous}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            disabled={dis}
            onClick={Continue}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none disabled:opacity-30"
          >
            {t("lang.mobile.button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
