import {
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOG_OUT,
  AUTH_ERROR,
} from "../actions/types";

const initialState = {
  token: sessionStorage.getItem("token"),
  person: sessionStorage.getItem("user"),
  isAuthenticated: null,
  user_type: null,
  user: null,
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user_type: payload.user_type,
        user: payload,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        user_type: payload.user_type,
        isAuthenticated: true,
        user: payload,
      };
    case AUTH_ERROR:
    case LOG_OUT:
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        person: null,
        isAuthenticated: false,
        user_type: null,
        user: null,
      };
    default:
      return state;
  }
}

export default authReducer;
