import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Typography,
  Button,
  ButtonGroup,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  CardHeader,
  Chip,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import {
  PencilIcon,
  ArchiveBoxIcon,
  PlayIcon,
  StopIcon,
} from "@heroicons/react/24/solid";

import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import {
  createAuto,
  createManual,
  loadRecycleEmails,
  changeStatus,
  deleteEmail,
  editEmailFunc,
  loadTempEmails,
} from "../../actions/tempAction";
import { Select, Option } from "@material-tailwind/react";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Sidebar from "../layout/Sidebar";
import { useTranslation } from "react-i18next";
import footer_button from "../layout/footer_button";
import Header from "../layout/Header";
import { format } from "date-fns";
import Foot_but from "../layout/Foot_but";
import Profile_sidebar from "../layout/Profile_sidebar";
import Spinner from "../layout/Spinner";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const Recycle = ({
  user,
  emailList,
  createAuto,
  createManual,
  loadRecycleEmails,
  changeStatus,
  deleteEmail,
  editEmailFunc,
  loading,
}) => {
  const { t } = useTranslation();

  const isIdle = useIdleDetection(600000); // 10 minutes

  const TABLE_HEAD2 = [
    t("lang.TABLE_HEAD2.No"),
    t("lang.TABLE_HEAD2.Temporary Email"),
    t("lang.TABLE_HEAD2.Password"),
    t("lang.TABLE_HEAD2.Status"),
    t("lang.TABLE_HEAD2.Restore"),
  ];

  const [search, setSearch] = React.useState("");
  const [pageSize, setPageSize] = React.useState("5");
  const [firstIndex, setFirstIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(4);
  const [pageIndex, setPageIndex] = React.useState(0);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const emailListData = emailList;
  let getData = [];
  let searchData = [];

  if (isIdle) {
    logout()
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

const handlePrevious = () => {
  const newIndex = Math.max(0, firstIndex - parseInt(pageSize));
  setFirstIndex(newIndex);
  setLastIndex(Math.min(emailListData.length - 1, newIndex + parseInt(pageSize) - 1));
  setPageIndex(newIndex);
};

const handleNext = () => {
  if (lastIndex < emailListData.length - 1) {
    const newIndex = Math.min(
      emailListData.length - 1,
      lastIndex + 1
    );
    setFirstIndex(newIndex);
    setLastIndex(Math.min(emailListData.length - 1, newIndex + parseInt(pageSize) - 1));
    setPageIndex(newIndex);
  }
};

const handlePageSize = (value) => {
  setPageSize(value);
};

  const user_id = user.id;
  useEffect(() => {
    loadRecycleEmails({ id: user_id, value: user.user_type });
    setLastIndex(pageSize - 1);
  }, [pageSize]);

  let countData = 0;

  for (let i = 0; i < emailListData.length; i++) {
    if (
      emailListData[i] &&
      emailListData[i].temp_email
        .toLowerCase()
        .includes(search.toLowerCase()) &&
      (emailListData[i].deleted_at =
        !null || emailListData[i].email_status == "locked")
    ) {
      searchData.push(emailListData[i]);
      countData += 1;
    }
  }

  const get_table_data = (firstIndex, lastIndex) => {
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (searchData[i]) {
        getData.push(searchData[i]);
      }
    }
  };
  get_table_data(firstIndex, lastIndex);

  const TABLE_ROWS = getData.map(
    (
      {
        id,
        temp_email,
        temp_password,
        email_status,
        route_id,
        deleted_at,
        updated_at,
        user_id,
      },
      index
    ) => {
      return {
        id,
        password: temp_password,
        route_id,
        updated_at,
        deleted_at,
        user_id,
        status: email_status,
        no: pageIndex + index + 1,
        temp_email,
      };
    }
  );

  const [openManual, setOpenManual] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);

  const [edt_email, setEdtEmail] = React.useState("");
  const [edt_password, setEdtPassword] = React.useState("");

  const [edit_id, setId] = React.useState("");

  const [domain, setDomain] = React.useState("@egener.pw");

  const handleDomainChange = (e) => {
    setDomain(e);
  };

  const handleOpenManual = () => setOpenManual((cur) => !cur);
  const handleEditEmail = () => setEditEmail((cur) => !cur);

  const createTempAuto = () => {
    createAuto({ email: user.email });
  };
  const [inputData, setInputData] = useState({
    temp_email: "",
    password: "",
  });
  const { temp_email, password } = inputData;
  const onChangeInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleEditIcon = (id, temp_email, password) => {
    setId(id);
    setEdtEmail(temp_email);
    setEdtPassword(password);
    handleEditEmail();
  };

  const onChangeEditEmail = (e) => {
    setEdtEmail(e.target.value);
  };

  const onChangeEditPassword = (e) => {
    setEdtPassword(e.target.value);
  };

  const onSubmitManual = (e) => {
    e.preventDefault();
    createManual({
      email: user.email,
      temp_email: temp_email + domain,
      password,
    });
  };

  const onSubmitEdit = (e) => {
    e.preventDefault();
    editEmailFunc({
      id: edit_id,
      user_id: user.id,
      temp_email: edt_email,
      password: edt_password,
    });
  };

  const handleChangeStatus = (
    temp_email_id,
    temp_email,
    origin,
    target,
    id
  ) => {
    changeStatus({
      temp_email_id,
      temp_email,
      origin,
      target,
      id,
      user_type: user.user_type,
    });
  };

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Profile_sidebar />
      <Divider orientation="vertical" flexItem />
      <div className="w-full flex flex-col justify-center">
        <Card className="h-full w-full shadow-none">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none m-0"
          >
            <div className="flex flex-col justify-between md:flex-row md:items-center p-4">
              <div>
                Number of Deleted Emails: <b>{countData}</b>
              </div>
              <div className="w-full md:w-72">
                <Input
                  id="search"
                  onChange={handleSearch}
                  label="Email Search"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                />
              </div>
            </div>
          </CardHeader>
          {emailList.length == 0 ? (
            <div className="flex justify-center text-xl font-semibold">
              Recyle is empty{" "}
            </div>
          ) : (
            <>
              <CardBody className="p-0">
                <div className="overflow-auto h-[50dvh]">
                  {loading ? (
                    <div className="w-full flex justify-center">
                      <Spinner />
                    </div>
                  ) : (
                    <table className="w-full text-center">
                      <thead>
                        <tr className="border-y border-blue-gray-100 bg-blue-gray-50">
                          {TABLE_HEAD2.map((head) => (
                            <th key={head} className="p-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {TABLE_ROWS.map(
                          (
                            { id, no, temp_email, password, status, route_id },
                            index
                          ) => {
                            const isLast = index === TABLE_ROWS.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={no}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-bold"
                                    >
                                      {no}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {temp_email}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {password}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    // value={status }
                                    value={
                                      (emailList.deleted_at = !null
                                        ? "Deleted"
                                        : "Locked")
                                    }
                                    // value={status == (null || 0 )? "inactive" : "active"}
                                    // color={status === (0 || null) ? "yellow" : "green"}
                                    color={
                                      // status === 'deleted' ? "blue" : "red"
                                      (emailList.deleted_at = !null
                                        ? "blue"
                                        : "red")
                                    }
                                  />
                                </td>
                                 {
                                  (emailList.deleted_at = !null ? (
                                    <td className={classes}>
                                      <Tooltip content="Change to inactive">
                                        <IconButton
                                          variant="text"
                                          color="blue-gray"
                                          onClick={() =>
                                            handleChangeStatus(
                                              id,
                                              temp_email,
                                              "deleted",
                                              "inactive",
                                              user.id
                                            )
                                          }
                                        >
                                          <PlayIcon className="h-4 w-4" />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  ) : (
                                    <td className={classes}>
                                      <Tooltip>
                                        <IconButton
                                          variant="text"
                                          color="blue-gray"
                                          onClick={() =>
                                            handleChangeStatus(
                                              id,
                                              temp_email,
                                              "inactive",
                                              "deleted",
                                              user.id
                                            )
                                          }
                                        >
                                          <PlayIcon className="h-4 w-4" />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  ))
                                } 
                                 
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </CardBody>
              <CardFooter className="flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-between border-t border-blue-gray-50 p-4">
                <Button
                  variant="outlined"
                  color="blue-gray"
                  size="sm"
                  onClick={handlePrevious}
                  className="w-full md:w-auto"
                >
                  Previous
                </Button>
                <div className="w-full md:w-auto">
                  <Select
                    label="Rows per page"
                    value={pageSize}
                    onChange={handlePageSize}
                  >
                    <Option value={"5"}>5</Option>
                    <Option value={"10"}>10</Option>
                    <Option value={"20"}>20</Option>
                    <Option value={"50"}>50</Option>
                    <Option value={"100"}>100</Option>
                  </Select>
                </div>

                <Button
                  variant="outlined"
                  color="blue-gray"
                  size="sm"
                  onClick={handleNext}
                  className="w-full md:w-auto"
                >
                  Next
                </Button>
              </CardFooter>
            </>
          )}
        </Card>
        <Foot_but user={user} />
      </div>
    </div>
  );
};

Recycle.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  createAuto: PropTypes.func.isRequired,
  createManual: PropTypes.func.isRequired,
  loadRecycleEmails: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  emailList: state.tempReducer.temporary_email,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  createAuto,
  createManual,
  loadRecycleEmails,
  changeStatus,
  deleteEmail,
  editEmailFunc,
  logout
})(Recycle);
